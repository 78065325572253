.EmojiPickerReact .epr-skin-tones {
  --epr-skin-tone-size: 15px;
}

.EmojiPickerReact .epr-skin-tones {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s ease-in-out;
  padding: 10px 0;
}

.EmojiPickerReact .epr-skin-tones.epr-vertical {
  padding: 5px;
  align-items: flex-end;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--epr-bg-color);
}

.EmojiPickerReact .epr-skin-tones.epr-vertical.epr-open {
  border: 1px solid var(--epr-picker-border-color);
  backdrop-filter: blur(5px);
  background: var(--epr-skin-tone-picker-menu-color);
}

.EmojiPickerReact .epr-skin-tone-select {
  position: relative;
  width: var(--epr-skin-tone-size);
  height: var(--epr-skin-tone-size);
}

.EmojiPickerReact .epr-skin-tones.epr-open .epr-tone {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.EmojiPickerReact .epr-skin-tones:not(.epr-open) .epr-tone {
  z-index: 0;
  opacity: 0;
}

.EmojiPickerReact .epr-skin-tones .epr-tone.epr-active {
  z-index: 1;
}

.EmojiPickerReact .epr-skin-tones .epr-tone {
  display: block;
  cursor: pointer;
  border-radius: 4px;
  height: var(--epr-skin-tone-size);
  width: var(--epr-skin-tone-size);
  position: absolute;
  right: 0;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  z-index: 1;
  box-shadow: 0 0 0 0px var(--epr-active-skin-hover-color);
}

.EmojiPickerReact .epr-skin-tones .epr-tone:hover {
  box-shadow: 0 0 0 3px var(--epr-active-skin-hover-color);
}

.EmojiPickerReact .epr-skin-tones .epr-tone:focus {
  box-shadow: 0 0 0 3px var(--epr-focus-bg-color);
}

.EmojiPickerReact
  .epr-skin-tones.epr-open
  .epr-skin-tone-select
  .epr-tone.epr-active:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 5px;
  border: 1px solid var(--epr-active-skin-tone-indicator-border-color);
}

.EmojiPickerReact .epr-skin-tones .epr-tone.epr-active {
  opacity: 1;
  z-index: 1;
}

.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-neutral {
  background-color: #ffd225;
}
.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-1f3fb {
  background-color: #ffdfbd;
}
.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-1f3fc {
  background-color: #e9c197;
}
.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-1f3fd {
  background-color: #c88e62;
}
.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-1f3fe {
  background-color: #a86637;
}
.EmojiPickerReact .epr-skin-tones .epr-tone.epr-tone-1f3ff {
  background-color: #60463a;
}
