.EmojiPickerReact {
  --epr-highlight-color: #007aeb;
  --epr-hover-bg-color: #f1f8ff;
  --epr-category-navigation-bg-color: rgba(247, 247, 247, 1);
  --epr-focus-bg-color: #e0f0ff;
  --epr-text-color: #858585;
  --epr-search-input-bg-color: #f6f6f6;
  --epr-picker-border-color: #e7e7e7;
  --epr-bg-color: #fff;
  --epr-category-icon-active-color: #6aa8de;
  --epr-skin-tone-picker-menu-color: #ffffff95;
  --epr-category-label-margin: 0;
  --epr-horizontal-padding: 10px;
  --epr-search-text-color: rgba(26, 26, 26, 0.7);
  --epr-category-icon-color: rgba(26, 26, 26, 0.7);
  --epr-picker-border-radius: 8px;

  /* Header */
  --epr-search-border-color: var(--epr-subtle-border-color);
  --epr-header-padding: 10px var(--epr-horizontal-padding);

  /* Skin Tone Picker */
  --epr-active-skin-tone-indicator-border-color: var(--epr-highlight-color);
  --epr-active-skin-hover-color: var(--epr-hover-bg-color);

  /* Search */
  --epr-search-input-bg-color-active: var(--epr-search-input-bg-color);
  --epr-search-input-padding: 0 10px;
  --epr-search-input-border-radius: 8px;
  --epr-search-input-height: 40px;
  --epr-search-input-text-color: var(--epr-search-text-color);
  --epr-search-input-placeholder-color: var(--epr-text-color);
  --epr-search-bar-inner-padding: var(--epr-horizontal-padding);
  --epr-subtle-border-color: rgba(230, 230, 230, 1);

  /*  Category Navigation */
  --epr-category-navigation-button-size: 30px;
  --epr-active-skin-hover-color: var(--epr-hover-bg-color);
  --epr-category-navigation: var(--epr-category-navigation-bg-color);

  /* Variation Picker */
  --epr-emoji-variation-picker-height: 45px;
  --epr-emoji-variation-picker-bg-color: var(--epr-bg-color);

  /*  Preview */
  --epr-preview-height: 70px;
  --epr-preview-text-size: 14px;
  --epr-preview-text-padding: 0 var(--epr-horizontal-padding);
  --epr-preview-border-color: var(--epr-picker-border-color);
  --epr-preview-text-color: var(--epr-text-color);

  /* Category */
  --epr-category-padding: 0 var(--epr-horizontal-padding);

  /*  Category Label */
  --epr-category-label-bg-color: #ffffffe6;
  --epr-label-text-color: rgba(26, 26, 26, 0.7);
  --epr-category-label-text-color: var(--epr-label-text-color);
  --epr-category-label-padding: 0 var(--epr-horizontal-padding);
  --epr-category-label-height: 40px;

  /*  Emoji */
  --epr-emoji-size: 30px;
  --epr-emoji-padding: 5px;
  --epr-emoji-fullsize: calc(
    var(--epr-emoji-size) + var(--epr-emoji-padding) * 2
  );
  --epr-emoji-hover-color: var(--epr-hover-bg-color);
  --epr-emoji-variation-indicator-color: var(--epr-picker-border-color);
  --epr-emoji-variation-indicator-color-hover: var(--epr-text-color);

  /* Z-Index */
  --epr-header-overlay-z-index: 3;
  --epr-emoji-variations-indictator-z-index: 1;
  --epr-category-label-z-index: 2;
  --epr-skin-variation-picker-z-index: 5;
  --epr-preview-z-index: 6;

  /* Dark Theme Variables */
  --epr-dark: #000;
  --epr-dark-emoji-variation-picker-bg-color: var(--epr-dark);
  --epr-dark-highlight-color: #c0c0c0;
  --epr-dark-text-color: var(--epr-highlight-color);
  --epr-dark-hover-bg-color: #363636f6;
  --epr-dark-focus-bg-color: #474747;
  --epr-dark-search-input-bg-color: #333333;
  --epr-dark-category-label-bg-color: #222222e6;
  --epr-dark-category-navigation-bg-color: rgba(12, 12, 12, 1);
  --epr-dark-picker-border-color: #151617;
  --epr-dark-bg-color: rgba(24, 24, 24, 1);
  --epr-dark-search-input-bg-color-active: var(--epr-dark);
  --epr-dark-emoji-variation-indicator-color: #444;
  --epr-dark-category-icon-active-color: #3271b7;
  --epr-dark-skin-tone-picker-menu-color: #22222295;
  --epr-dark-subtle-border-color: #484848;
  --epr-dark-label-text-color: rgba(255, 255, 255, 0.65);
  --epr-dark-category-label-margin: 10px;
  --epr-dark-search-text-color: rgba(255, 255, 255, 0.65);
  --epr-dark-category-icon-color: rgba(255, 255, 255, 0.65);
}

@media (prefers-color-scheme: dark) {
  /* Keep in sync with the section below */
  .EmojiPickerReact.epr-auto-theme {
    --epr-emoji-variation-picker-bg-color: var(
      --epr-dark-emoji-variation-picker-bg-color
    );
    --epr-highlight-color: var(--epr-dark-highlight-color);
    --epr-text-color: var(--epr-dark-text-color);
    --epr-label-text-color: var(--epr-dark-label-text-color);
    --epr-hover-bg-color: var(--epr-dark-hover-bg-color);
    --epr-category-navigation-bg-color: var(
      --epr-dark-category-navigation-bg-color
    );
    --epr-focus-bg-color: var(--epr-dark-focus-bg-color);
    --epr-search-input-bg-color: var(--epr-dark-search-input-bg-color);
    --epr-category-label-bg-color: var(--epr-dark-category-label-bg-color);
    --epr-category-navigation-bg-color: var(
      --epr-dark-category-navigation-bg-color
    );
    --epr-picker-border-color: var(--epr-dark-picker-border-color);
    --epr-bg-color: var(--epr-dark-bg-color);
    --epr-search-input-bg-color-active: var(
      --epr-dark-search-input-bg-color-active
    );
    --epr-emoji-variation-indicator-color: var(
      --epr-dark-emoji-variation-indicator-color
    );
    --epr-category-icon-active-color: var(
      --epr-dark-category-icon-active-color
    );
    --epr-skin-tone-picker-menu-color: var(
      --epr-dark-skin-tone-picker-menu-color
    );
    --epr-subtle-border-color: var(--epr-dark-subtle-border-color);
    --epr-category-label-margin: var(--epr-dark-category-label-margin);
    --epr-search-text-color: var(--epr-dark-search-text-color);
    --epr-category-icon-color: var(--epr-dark-category-icon-color);
  }
}

.EmojiPickerReact.epr-dark-theme {
  /* Keep in sync with the section above */
  --epr-emoji-variation-picker-bg-color: var(
    --epr-dark-emoji-variation-picker-bg-color
  );
  --epr-highlight-color: var(--epr-dark-highlight-color);
  --epr-text-color: var(--epr-dark-text-color);
  --epr-label-text-color: var(--epr-dark-label-text-color);
  --epr-hover-bg-color: var(--epr-dark-hover-bg-color);

  --epr-focus-bg-color: var(--epr-dark-focus-bg-color);
  --epr-search-input-bg-color: var(--epr-dark-search-input-bg-color);
  --epr-category-label-bg-color: var(--epr-dark-category-label-bg-color);
  --epr-category-navigation-bg-color: var(
    --epr-dark-category-navigation-bg-color
  );
  --epr-picker-border-color: var(--epr-dark-picker-border-color);
  --epr-bg-color: var(--epr-dark-bg-color);
  --epr-search-input-bg-color-active: var(
    --epr-dark-search-input-bg-color-active
  );
  --epr-emoji-variation-indicator-color: var(
    --epr-dark-emoji-variation-indicator-color
  );
  --epr-category-icon-active-color: var(--epr-dark-category-icon-active-color);
  --epr-skin-tone-picker-menu-color: var(
    --epr-dark-skin-tone-picker-menu-color
  );
  --epr-subtle-border-color: var(--epr-dark-subtle-border-color);
  --epr-category-label-margin: var(--epr-dark-category-label-margin);
  --epr-search-text-color: var(--epr-dark-search-text-color);
  --epr-category-icon-color: var(--epr-dark-category-icon-color);
}

.EmojiPickerReact {
  background-color: var(--epr-bg-color);
  overflow: hidden;
}

.EmojiPickerReact .epr-hidden {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

.EmojiPickerReact.epr-search-active .epr-hidden-on-search {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

.EmojiPickerReact:not(.epr-search-active) .epr-visible-on-search-only {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

/*  This is the same as .search-active, only without any js behind it
    Should switch to it full time when :has() gets better adoption
*/
aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown))
  .epr-hidden-on-search {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

aside.EmojiPickerReact.epr-main:has(input:placeholder-shown)
  .epr-visible-on-search-only {
  display: none !important;
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
}

.EmojiPickerReact * {
  box-sizing: border-box;
  font-family: sans-serif;
}

.EmojiPickerReact button.epr-btn {
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
}
