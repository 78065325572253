.EmojiPickerReact button.epr-emoji {
  position: relative;
  width: var(--epr-emoji-fullsize);
  height: var(--epr-emoji-fullsize);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--epr-emoji-fullsize);
  max-height: var(--epr-emoji-fullsize);
  border-radius: 8px;
  overflow: hidden;
}

.EmojiPickerReact button.epr-emoji.epr-emoji-has-variations:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  right: 0px;
  bottom: 1px;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transform: rotate(135deg);
  border-bottom: 4px solid var(--epr-emoji-variation-indicator-color);
  z-index: var(--epr-emoji-variations-indictator-z-index);
}

.EmojiPickerReact button.epr-emoji .epr-emoji-img {
  max-width: var(--epr-emoji-fullsize);
  max-height: var(--epr-emoji-fullsize);
  min-width: var(--epr-emoji-fullsize);
  min-height: var(--epr-emoji-fullsize);
  padding: var(--epr-emoji-padding);
}

.EmojiPickerReact button.epr-emoji .epr-emoji-native {
  padding: var(--epr-emoji-padding);
}

.EmojiPickerReact button.epr-emoji > * {
  align-self: center;
  justify-self: center;
  display: flex;
}

.EmojiPickerReact button.epr-emoji.epr-emoji-has-variations:hover:after {
  border-bottom: 4px solid var(--epr-emoji-variation-indicator-color-hover);
}

.__EmojiPicker__.epr-emoji-img {
  font-size: 0;
}

.__EmojiPicker__.epr-emoji-native {
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI',
    'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'Android Emoji',
    Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif,
    LastResort;
  position: relative;
  line-height: 100%;
  font-size: var(--epr-emoji-size);
  text-align: center;
  align-self: center;
  justify-self: center;
  letter-spacing: 0;
}

.EmojiPickerReact button.epr-emoji > * {
  transition: background-color 0.2s;
}

.EmojiPickerReact button.epr-emoji:hover > * {
  background-color: var(--epr-emoji-hover-color);
}

.EmojiPickerReact button.epr-emoji:focus > * {
  background-color: var(--epr-focus-bg-color);
}
