.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-content {
  position: relative;
  margin: var(--epr-category-padding);
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--epr-emoji-fullsize));
  grid-gap: 0;
  justify-content: space-between;
}

.EmojiPickerReact li.epr-emoji-category:not(:has(.epr-visible)) {
  display: none;
}

.EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin: var(--epr-category-label-margin) 0 var(--epr-category-label-margin) 0;
  text-transform: capitalize;
  backdrop-filter: blur(3px);
  padding: var(--epr-category-label-padding);
  background-color: var(--epr-category-label-bg-color);
  color: var(--epr-category-label-text-color);
  z-index: var(--epr-category-label-z-index);
  height: var(--epr-category-label-height);
}
